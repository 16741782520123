import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import BssidEditMenu from '../../containers/BssidEditMenu/bssidEditMenu';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import { createStructuredSelector } from 'reselect';
import { actions as AppActions } from '../../reducers/app';
import { makeSelectBssidPageCount, actions as BssidActions } from '../../reducers/networkDiscovery';
import { connect } from "react-redux";


function BssidTable(props) {
    const {
        sortColumns,
        setSortColumns,
        pageIndex,
        setPageIndex,
        pageSize,
        setPageSize,
        currentSortColumn,
        setCurrentSortColumn
    } = props;

    const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
	const isEdit = user && user.permissions.includes('EDIT_NETWORK_DISCOVERY');
	const isDelete = user && user.permissions.includes('DELETE_NETWORK_DISCOVERY');
    const isView = isEdit || isDelete;

    function getGeoCoordsNetDisco(row) {
        if ((row.original.location) && (row.original.location.normalizedGeoCoords) && (row.original.location.normalizedGeoCoords.latitude) && (row.original.location.normalizedGeoCoords.longitude)) {
            return <div title={`${row.original.location.normalizedGeoCoords.latitude} , ${row.original.location.normalizedGeoCoords.longitude}`}>{`${row.original.location.normalizedGeoCoords.latitude} , ${row.original.location.normalizedGeoCoords.longitude}`}</div>;
        } else {
            return <div></div>;
        }
    }

    const buildTableColumns = [
        {
            Header: "BSSID",
            id: "bssid",
            Cell: row => <div title={row.original.bssid}>{row.original.bssid}</div>
        },
        {
            Header: "Location",
            id: "location",
            sortable:true,
            Cell: row => <div title={`${row.original.location ? row.original.location.name : 'Unknown'} ${(row.original.location && row.original.location.address) ? row.original.location.address.normalizedAddress : ''}`}>{row.original.location ? row.original.location.name: 'Unknown'}<br/>{(row.original.location && row.original.location.address) ? row.original.location.address.normalizedAddress : ''}</div>
        },
        {
            Header: <div id="location-coordinates">Geodetic Coordinates</div>,
            id: "coordinates",
            Cell: row => getGeoCoordsNetDisco(row)
        },
        {
            Header: "Description",
            id: "description",
            Cell: row => <div title={row.original.description}>{row.original.description}</div>
        },
        {
            Header: "",
            width: 65,
            id: "edit-menu",
            Cell: row => (
                isView ? <BssidEditMenu row={row} setCurrentModal={props.setCurrentModal}
                currentSortColumn={props.currentSortColumn} 
                /> : null
            )
        }
    ];

    const {
        loading, bssidList, bssidPageCount, getBssidListRequest
    } = props;
    let bssidListArray = [];

    if (bssidList) {
        bssidListArray = bssidList.toArray();
    }

    useEffect(() => {
        if (bssidListArray && bssidListArray.length === 0 && pageIndex > 0 && bssidPageCount < pageIndex+1) {
            setPageIndex(pageIndex-1)
        }
    }, [bssidListArray && bssidListArray.length === 0 && pageIndex > 0 && bssidPageCount < pageIndex+1]);

    function resetScrollInsideTable() {
        let tableBody = document.querySelector('.rt-tbody');
        tableBody.scrollTop = 0;
    }

    useEffect( () => {
        getBssidListRequest(pageIndex + 1, pageSize, currentSortColumn.id, currentSortColumn.desc ? 'DESC': 'ASC');
        props.setTableProps({
            page: pageIndex+1, 
            pageSize: pageSize, 
            sortBy: currentSortColumn.id, 
            direction: currentSortColumn.desc ? 'DESC': 'ASC'
        });
    }, [currentSortColumn, pageSize, pageIndex]);

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);
    
    return (
        <ReactTable
            resizable={false}
            sortable={false}
            className="-striped -highlight"
            showPaginationBottom={true}
            data={bssidListArray}
            minRows={bssidListArray && bssidListArray.length > 0 ? 0 : 5}
            pages={bssidPageCount <= 0 ? 1 : bssidPageCount}
            page= {pageIndex && bssidPageCount > 0 && pageIndex + 1 <= bssidPageCount ? pageIndex : 0 }
            defaultPageSize = {25}
            columns={buildTableColumns}
            loading = {loading}
            LoadingComponent={ReactTableLoadingComponent}
            manual
            
            sorted={sortColumns}
            onSortedChange={(newSorted, column, shiftKey) => {
                let sortedArray = [...sortColumns];
                let currentSortColumn;
                sortedArray.forEach( (columnObject, index) => {                    
                    if(column.id === columnObject.id) {
                        // state needs updated object reference, doesn't detect nested fields
                        currentSortColumn = {...columnObject};
                        currentSortColumn.desc = !currentSortColumn.desc;
                        sortedArray[index] = currentSortColumn;
                    }
                    else {
                        let resetColumn = {...columnObject};
                        resetColumn.desc = false;
                        sortedArray[index] = resetColumn;
                    }
                    setCurrentSortColumn(currentSortColumn);
                    setSortColumns(sortedArray);
                })
                
            }}

            onPageSizeChange={pageSize => {
                setPageSize(pageSize);
                setPageIndex(0);
                resetScrollInsideTable();
            }}
            onPageChange={(pageIndex) => {
                setPageIndex(pageIndex);
                resetScrollInsideTable();
            }}

            getTrProps={
                (state, rowInfo, column) => {
                    if(rowInfo && !rowInfo.original.location) {
                            return {
                                id: 'bssid-row-' + rowInfo.index,
                                style: {
                                    background: 'rgba(255, 70, 71, 0.45)',
                                    opacity: '.8',
                                    border: '1px solid red'
                                }
                            };
                        }
                        else {
                            return {
                                id: rowInfo ? 'bssid-row-' + rowInfo.index : '',
                            };
                        }
                    }
            }
        />
    );
}

BssidTable.propTypes = {
    bssidList: ImmutablePropTypes.list,
    loading: PropTypes.bool.isRequired,
    setCurrentModal: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
    bssidPageCount: makeSelectBssidPageCount()
});

const mapDispatchToProps = dispatch => {
    return {
        getBssidListRequest: (page, pageSize, sortBy, direction) => dispatch(BssidActions.getBssidRequest(page, pageSize, null, sortBy, direction)),
        setTableProps: (tableProps) => dispatch(AppActions.setTableProps(tableProps))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BssidTable);
